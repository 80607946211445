@font-face {
font-family: '__GeistSans_3a0388';
src: url(/_next/static/media/e11418ac562b8ac1-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__GeistSans_Fallback_3a0388';src: local("Arial");ascent-override: 85.83%;descent-override: 20.52%;line-gap-override: 9.33%;size-adjust: 107.19%
}.__className_3a0388 {font-family: '__GeistSans_3a0388', '__GeistSans_Fallback_3a0388'
}.__variable_3a0388 {--font-geist-sans: '__GeistSans_3a0388', '__GeistSans_Fallback_3a0388'
}

